import axios from "axios";

const apiClient = axios.create({
  baseURL: "https://kreps-grafik.de/cms/api",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default {
  getCollections() {
    return apiClient.get(
      `/collections/listCollections?token=981bb4b26ec1f9745e6d528196f455`
    );
  },
  getCollectionSchema(collection) {
    return apiClient.get(
      `/collections/collection/${collection}?token=981bb4b26ec1f9745e6d528196f455`
    );
  },
  getCollectionEntries(collection) {
    return apiClient.get(
      `/collections/get/${collection}?token=981bb4b26ec1f9745e6d528196f455`
    );
  },
};
